html {
	box-sizing: border-box;
	font-size: 18px;
	line-height: 1.5;
	-webkit-tap-highlight-color: transparent;
}

body {
	background: #fff;
	color: #3b4351;
	font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,sans-serif;
	font-size: .8rem;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
}

.h5, h5 {
	font-size: 1.3rem;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 500;
}

.text-muted {
	color: #949aa2;
}

.btn {
	font-size: 16px;
	border-radius: 2px;
	padding: .25rem .4rem;
}

.btn-primary,
.ant-btn-primary {
	color: #fff;
	border-color: #e42537;
	background: #e42537;
}

.btn-outline-primary,
.btn-outline-primary.disabled, .btn-outline-primary:disabled,
.ant-btn:hover, .ant-btn:focus {
	background: #fff;
	color: #e42537;
	border-color: #e42537;
}

.btn-primary:hover, 
.btn-primary:visited, 
.btn-primary:focus, 
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle,
.btn-primary.disabled, 
.btn-primary:disabled,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-btn-primary:visited {
	background: #c31a2a;
	border-color: #c31a2a;
	color: #fff;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
	color: #fff;
	background: #e42537;
	border-color: #e42537;
}

a, a:visited, .text-primary {
	color: #e42537 !important;
}

a:hover, a:focus, a:active,
.text-primary:hover, .text-primary:focus, .text-primary:active {
	color: #c31a2a !important;
}

label.form-label {
	margin-bottom: 3px;
	font-weight: 500;
}

.full-width {
	width: 100%;
}

.nav-toggle-icon {
	font-size: 18px;
	cursor: pointer;
	display: inline-block;
	position: relative;
	top: 2px;
}

.text-link {
	color: #e42537;
	cursor: pointer;
}

.text-link:hover, .text-link:focus, .text-link:active {
	color:#c31a2a;
}

.card-title {
	color: #3b4351;
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 3px;
}

.card-subtitle {
	color: #949aa2;
	font-size: 16px;
}

.card {
	border: none;
	border-radius: 0px;
	-webkit-box-shadow: 0 0.25rem 1rem rgba(48,55,66,.15);
	box-shadow: 0 0.25rem 1rem rgba(48,55,66,.15);
	margin-bottom: 20px;
}

.card-body {
	padding: .8rem;
}

.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #fff;
	background-image: none;
	border: .05rem solid #bcc3ce;
	border-radius: .1rem;
	color: #3b4351;
	display: block;
	font-size: 0.9rem;
	height: 2rem;
	line-height: 1.2rem;
	max-width: 100%;
	outline: none;
	padding: .25rem .4rem;
	position: relative;
	transition: background .2s,border .2s,box-shadow .2s,color .2s;
	width: 100%;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
	background-color: #c31a2a;
}

.ant-steps-label-vertical .ant-steps-item-title {
	font-weight: 500;
}

.modal-title {
	font-size: 1.1rem;
}

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot, 
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
	left: 0px;
	top: 1px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	font-size: 16px;
}

.ant-picker-input > input {
	font-size: 16px;
}

.ant-spin-dot-item {
	background-color: #e42537;
}

.form-group {
	margin-bottom: 13px;
}

.btn-primary.disabled, 
.btn-primary:disabled {
	cursor: not-allowed;
}

.bold {
	font-weight: bold;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgb(173 173 173 / 50%);
}

.ant-message {
	z-index: 1090;
}

.line-through {
	text-decoration: line-through;
}

.items-list.ant-list-vertical .ant-list-item-meta-title {
	margin-bottom: 0px;
}

.items-list.ant-list-vertical .ant-list-item-meta {
	margin-bottom: 5px;
}

.items-list.ant-list-vertical .ant-list-item-action {
	margin-top: 0px;
}

.inline-block {
	display: inline-block;
}

.ant-table {
	font-size: 15px;
}

@media screen and (max-width: 576px){
	.ant-list-vertical .ant-list-item {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.ant-list-vertical .ant-list-item-extra {
		margin: 20px 0px 0px;
		width: 100%;
	}

	.ant-list-vertical .ant-list-item-extra .text-right { 
		text-align: left!important;
	}
}


@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.ant-pagination-item-active,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-open {
	border-color: #c31a2a;
}

.fs-18 {
	font-size: 18px;
}

.avales-img {
	width: 100%;
	max-width: 600px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arrowBtn {
	border-color: #e0e0e0;
}

span.input-group-text {
	padding: 6px 10px;
	border-radius: 0px;
	font-size: 0.9rem;
	font-weight: 500;
	border: none;
}

.listItem {
	padding: 10px 5px 20px 0px;
	border-bottom: 1px dashed;
}